














































import { logout } from '../../api/user';
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { SettingsModule } from '../../plugins/store';
import { Hub } from '@aws-amplify/core';

const settingsStore: SettingsModule = getModule(SettingsModule);

@Component
export default class AccountView extends Vue {
  private get showConsole(): boolean {
    return settingsStore.console;
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private logout(): void {
    logout().catch((error: Error): void => this.showError(error.message));
  }
}
